import React, { useState } from "react";
import { useAuth } from "../Context/authContext";
import {
  TextField,
  Button,
  Grid,
  useTheme,
  Paper,
  Link,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import urls from "../config";
import { tokens } from "../theme";
import { Dashboard } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleLogin = (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError("Please fill in all the fields to Login.");
      return;
    }

    setLoginLoading(true);
    fetch(`${urls.login}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => {
        if (response.ok) {
          // Login successful
          return response.json();
        } else {
          // Login failed
          throw new Error("Invalid Credentials");
        }
      })
      .then((data) => {
        login(data);
        setLoginLoading(false);
        localStorage.setItem("username", username);
      })
      .catch((error) => {
        setError(error.message);
        setLoginLoading(false);
      });
  };

  const handleToDashboard = () => {
    navigate("/dashboard");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item>
        <Paper style={{ padding: "2em" }}>
          <Grid
            container
            spacing={4}
            direction="column"
            alignItems="center"
            display={"flex"}
            justifyContent={"center"}
          >
            <Grid
              item
              display={"flex"}
              flexDirection={"Column"}
              alignItems={"center"}
            >
              <Dashboard
                style={{
                  fontSize: 40,
                  color: colors.blueAccent[400],
                  marginRight: 2,
                }}
              />
              <h3 style={{ color: colors.blueAccent[500] }}>
                Welcome to Domain Dashboard
              </h3>
            </Grid>
            <Grid item mt={-8}>
              <h1>Login</h1>
            </Grid>
            <Grid item mt={-5} sx={{ width: "90%" }}>
              <TextField
                required
                id="outlined-basic"
                name="username"
                className="Username"
                label="Username"
                variant="outlined"
                placeholder="Username"
                size="small"
                style={{ width: "100%" }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item sx={{ width: "90%" }}>
              <TextField
                required
                id="outlined-basic"
                className="Password"
                name="password"
                label="Password"
                variant="outlined"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item width={"88%"}>
              <Button
                size="small"
                onClick={handleLogin}
                fullWidth
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "bold",
                  backgroundColor: colors.blueAccent[600],
                  color: "white",
                }}
              >
                {loginLoading ? (
                  <CircularProgress
                    size={20}
                    style={{ color: "white", marginRight: 5 }}
                  />
                ) : null}{" "}
                Login
              </Button>
            </Grid>
            <Grid item>
              <Link onClick={handleToDashboard} style={{ cursor: "pointer" }}>
                Go to Dashboard!
              </Link>
            </Grid>
            <Grid item>{error && <p style={{ color: "red" }}>{error}</p>}</Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
